import { Injectable } from '@angular/core';
import { CommunityMemberStatus } from '@models/communities/community-member-status.enum';
import { Community } from '@models/communities/community.model';
import { CommunityApplyRequest } from '@models/communities/dto/community-apply.request';
import { CommunityMemberUpdateRequest } from '@models/communities/dto/community-member-update.request';
import { Event } from '@models/events/event.model';
import { ApiService } from '@services/api.service';
import { environment as env } from '@environments/environment';
import { Observable, Subject, Subscription } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { CommunityUpdateRequest } from '@models/communities/dto/community-update.request';

@Injectable()
export class CommunityService {
  private updateQueue = new Subject<{
    communityId?: number;
    req?: CommunityUpdateRequest;
    resolver: (community: Community) => void;
    rejecter: (err: any) => void;
  }>();
  private updateQueueSubscription: Subscription;

  constructor(private api: ApiService) {
    this.updateQueueSubscription = this.processUpdateQueue();
  }

  getCommunities(): Observable<Community[]> {
    return this.api
      .get(`${env.api}/communities`)
      .pipe(map(this.mapCommunities));
  }

  getCommunityByUri(uri: string): Observable<Community> {
    return this.api
      .get(`${env.api}/communities/uri/${uri}`)
      .pipe(map((it) => new Community(it)));
  }

  getCommunityByDomain(domain: string): Observable<Community> {
    return this.api
      .get(`${env.api}/communities/domain/${domain}`)
      .pipe(map((it) => new Community(it)));
  }

  update(
    communityId?: number,
    req?: CommunityUpdateRequest,
  ): Observable<Community> {
    const userLang = localStorage.getItem('userLang');
    if (req && userLang) {
      req.languageCode = userLang;
    }

    return new Observable<Community>((subscriber) => {
      this.updateQueue.next({
        communityId,
        req,
        resolver: (community) => {
          subscriber.next(community);
          subscriber.complete();
        },
        rejecter: (err) => {
          subscriber.error(err);
        },
      });
    });
  }

  private updateCommunityApiCall(
    communityId?: number,
    req?: CommunityUpdateRequest,
  ): Observable<Community> {
    return this.api
      .put(`${env.api}/communities/${communityId}`, req)
      .pipe(map((it) => new Community(it)));
  }

  private processUpdateQueue(): Subscription {
    return this.updateQueue
      .pipe(
        concatMap(({ communityId, req, resolver, rejecter }) =>
          this.updateCommunityApiCall(communityId, req).pipe(
            map((community) => {
              resolver(community);
              return community;
            }),
            catchError((err) => {
              rejecter(err);
              throw err;
            }),
          ),
        ),
      )
      .subscribe();
  }

  delete(id: number): Observable<void> {
    return this.api.delete(`${env.api}/communities/${id}`);
  }

  getCommunityEvents(communityId: number): Observable<Event[]> {
    return this.api
      .get(`${env.api}/communities/${communityId}/events`)
      .pipe(map(this.mapEvents));
  }

  setMemberStatus(
    communityId: number,
    memberId: number,
    status: CommunityMemberStatus,
  ) {
    return this.api.put(
      `${env.api}/communities/${communityId}/members/${memberId}/set-status`,
      { status: status },
    );
  }

  deleteMember(communityId: number, memberId: number): Observable<void> {
    return this.api.delete(
      `${env.api}/communities/${communityId}/members/${memberId}`,
    );
  }

  apply(communityId: number, request: CommunityApplyRequest) {
    return this.api.put(
      `${env.api}/communities/${communityId}/members/apply`,
      request,
    );
  }

  updateCustomerMember(
    communityId: number,
    request: CommunityMemberUpdateRequest,
  ) {
    return this.api.put(
      `${env.api}/communities/${communityId}/members`,
      request,
    );
  }

  private mapEvents(res: Event[]) {
    return res?.map((it) => new Event(it));
  }

  private mapCommunities(res: Community[]) {
    return res?.map((it) => new Community(it));
  }
}
